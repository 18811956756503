import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import '../../styles/Button/BlogButton.css'

const BlogButton = () => {
  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    // Use navigate('/about') to navigate to the "/about" route
    navigate('/blogHome');
  };

  return (
    <div className='button-blog' onClick={handleClick}>
    <div className='blog-text-con-1'>
      <div className='blog-text-1'>blog</div>
      </div>
      <div className='blog-text-con-2'>
      <div className='blog-text-2'>
      Welcome to our blog!    <br /> 
      In our blog, you'll find insightful articles and valuable
       information on a wide range of topics
        </div>
      </div>
      <div className='blog-svg-con'>
<div className='blog-svg-item'>
  <img src='img/Group24.svg' alt='upwork' />
</div>
      </div>
      
    </div>
);
};

export default BlogButton;