// src/App.js
import React, { useEffect } from 'react';
import Footer from './components/Fotter/Footer';
import RoutesConfig from './routes';
import './styles/global.scss'; // Import global styles
import Cursor from './components/Cursor/Cursor';
import ReactGA from 'react-ga';
import GoogleAnalytics from './components/GoogleAnalytic/GoogleAnalytic';
import reportWebVitals from './reportWebVitals'; // Import the modified reportWebVitals file
import { animateScroll as scroll } from 'react-scroll'; // Import animateScroll from react-scroll
import Header from './components/Header/Header'

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize('G-D8R1H9K2ER');

    // Track page view
    ReactGA.pageview(window.location.pathname + window.location.search);

    // Scroll to the top when the component mounts
    scroll.scrollToTop();
  }, []);

  // Custom callback to handle performance metrics
  const handlePerfEntry = (name, metric) => {
    console.log(`${name}:`, metric);
    // You can also send the metrics to Google Analytics or another analytics service here
  };

  // Initialize performance tracking
  useEffect(() => {
    reportWebVitals(handlePerfEntry);
  }, []);

  return (
    <div className="app">
      <Cursor />
      <GoogleAnalytics />
      <Header />
      <main className="main-content">
        <RoutesConfig />
      </main>
      <Footer />
    </div>
  );
};

export default App;
