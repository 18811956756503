import React, { useReducer } from "react";
import "../../styles/components/Header.css";
import { Link } from "react-router-dom";
import LogoImage from "../../assets/Group 68.svg";

// Define the reducer function
export const menuReducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_MENU":
      return { ...state, isMenuOpen: !state.isMenuOpen };
    default:
      return state;
  }
};

export const NavigationMenu = () => {
  // Initialize the state using useReducer
  const [state, dispatch] = useReducer(menuReducer, { isMenuOpen: false });

   const toggleMenu = () => {
    // Dispatch an action to toggle the menu
    dispatch({ type: "TOGGLE_MENU" });
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div></div>

        <div className="logo">
      <Link to="/"> {/* Change "/new-route" to the desired route */}
        <div className="logo-img">
          <img src={LogoImage} alt="Logo" className="img-hed" />
        </div>
      </Link>
    </div>

        <div
          className={`nav-button-header ${state.isMenuOpen ? "open" : ""}`}
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37.5px"
            height="30px"
            viewBox="0 0 42 34"
            fill="none"
            style={{
              backgroundColor: "transparent",
              flexShrink: 0,
              marginLeft: "5px", // Adjust the margin as needed
            }}
          >
            <path
              d="M39.5 2L2 2"
              stroke="#F1F1F1"
              strokeWidth="4"
              strokeLinecap="round"
            />
            <path
              d="M39.5 17L2 17"
              stroke="#F1F1F1"
              strokeWidth="4"
              strokeLinecap="round"
            />
            <path
              d="M39.5 32L2 32"
              stroke="#F1F1F1"
              strokeWidth="4"
              strokeLinecap="round"
            />
          </svg>

          {state.isMenuOpen}

          {state.isMenuOpen && (
            
  <div className="menu-dropdown menu-dropdown-open">
    <ul className="nav-links">
      <li>
        <a href="/">Home</a>
      </li>
   
     
      <li>
        <a href="/blogHome">Blog</a>
      </li>
      <li>
        <a href="/services-page">Services</a>
      </li>
      <li>
        <a href="/myprojects">MyProjects</a>
      </li>
      <li>
        <a href="/about">AboutMe</a>
      </li>
      {/* <li>
        <a href=" /QrCode"> QrCode</a>
      </li> */}
    </ul>
  </div>
)}

        </div>
      </nav>
    </header>
  );
};

export default NavigationMenu;
